import type { ZudokuConfig } from "zudoku";


const config: ZudokuConfig = {
  
  topNavigation: [
    { id: "bnpl", label: "🏠︎ API Home" },
    { id: "documentation", label: "📖 Documentation" },

  ],


  sidebar: {

    bnpl: [
      // Added additional docs
    { type: "doc", label: "🔑 APIs", id: "../bnpl"},
    ],
    documentation: [
      // Added additional docs
      { type: "category", label: "📔 Callbacks", items: ["Lead-Callbacks","Payment-Callbacks","Settlement-Callbacks","Repayment-Callbacks"] },
      { type: "doc", label: "Order-Flow-Diagram", id: "Order-Flow-Diagram"},
      { type: "doc", label: "In-app Integration", id: "In-app-Integration"},
      { type: "doc", label: "Handling UPI Intent in Android & iOS", id: "UPI-Intent-Android-iOS"},

      { type: "category", label: "📝 Schemas", items: ["/schemas/eligibility","/schemas/payment","/schemas/capture","/schemas/void","/schemas/refund","/schemas/account","/schemas/account-statement","/schemas/document","/schemas/amount","/schemas/metadata","/schemas/settlement","/schemas/settled-entity"]  },
      { type: "doc", label: "🔑 APIs", id: "../bnpl"},
    ],

  },

  redirects: [
    { from: "/", to: "/bnpl" },
],

  

  apis: [
    {
      type: "file",
      input: "./apis/bundled.json",
      navigationId: "bnpl",
      
    },
  ],

};

export default config;
